import React, { useState, useEffect } from "react"
import ThemeContext from "./theme-context"
import Cookies from "js-cookie"

const ThemeProvider = ({ element }) => {
  const [isDark, setIsDark] = useState(() => {
    const savedTheme = Cookies.get("theme")
    return savedTheme === "dark"
  })

  useEffect(() => {
    const html = document.documentElement
    if (isDark) {
      html.classList.add("dark")
      Cookies.set("theme", "dark", { expires: 30 })
    } else {
      html.classList.remove("dark")
      Cookies.set("theme", "light", { expires: 30 })
    }
  }, [isDark])

  return (
    <ThemeContext.Provider value={{ isDark, setIsDark }}>
      {element}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
