import React from "react"
import ThemeProvider from "./src/context/theme-provider"

// Tailwind
import "./src/styles/global.css"
import "./src/styles/markdown.css"

// Highlighting for code blocks
import "prismjs/themes/prism-twilight.css"

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider element={element} />
}
